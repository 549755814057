<template>
	<div id="skillDetail">
		<div class="skillDetail_main">
			<div class="breadcrumb">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/serviceSystem/home' }"
						>首页</el-breadcrumb-item
					>
					<el-breadcrumb-item :to="{ path: '/serviceSystem/skill', query: 2 }"
						>技能培训</el-breadcrumb-item
					>
					<el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div style="float: left">
				<div class="skills_head">
					<img class="headImg" :src="courseInfo.coverUrl" alt="" />
					<div class="detailInfo">
						<p class="title">{{ courseInfo.categoryName }}</p>
						<p class="price">￥{{ courseInfo.chargesRule }}</p>
						<p class="address">培训地点：{{ courseInfo.issuingAgency }}</p>
						<p class="timer">培训时长：{{ courseInfo.trainingOften }}</p>
						<p class="course">培训课程：{{ courseInfo.courseIntroduction }}</p>
					</div>
				</div>
				<div class="skills_body">
					<p class="courseTitle"><span>课程介绍</span></p>
					<div class="courseContent" v-html="courseInfo.trainingContent"></div>
				</div>
			</div>
			<div class="ideaCourse">
				<p class="ideaCourseTitle">推荐课程</p>
				<div class="ideaImg">
					<div
						class="ideaImg_box pointer"
						v-for="item in skillsData"
						:key="item.id"
						@click="toDetail(item.id)"
					>
						<img class="idea_img" :src="item.coverUrl" alt="" />
						<p>{{ item.categoryName }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GejMallPcSkillsdetail',

	data() {
		return {
			title: '',
			// 当前技能信息
			courseInfo: {},
			// 其余技能列表
			skillsData: [],
		}
	},

	mounted() {
		// 根据详细id-获取内容
		this.api.getInfoById({ id: this.$route.query.courseId }).then((res) => {
			if (res.data) {
				this.title = res.data.categoryName
				this.courseInfo = res.data
			}
		})

		this.getList()
	},

	methods: {
		// 查看详细技能信息
		toDetail(id) {
			this.api.getInfoById({ id }).then((res) => {
				if (res.data) {
					this.title = res.data.categoryName
					this.courseInfo = res.data
				}
			})
		},
		// 获取其他技能培训列表
		getList() {
			// 根据当前详细id-筛选出其他技能详细
			let skillsList = this.$store.state.skillsList
			let id = this.$route.query.courseId
			this.skillsData = skillsList.filter((item) => {
				if (item.id != id) {
					return item
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
#skillDetail {
	.skillDetail_main {
		width: 1200px;
		margin: 0 auto;
		position: relative;
		.skills_head {
			width: 819px;
			height: 248px;
			background: white;
			margin-bottom: 20px;
			padding: 20px 51px 19px 20px;
			display: flex;
			.headImg {
				width: 382px;
				height: 209px;
				padding-right: 30px;
			}
			.detailInfo {
				.title {
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					padding-top: 20px;
				}
				.price {
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #e5383d;
					padding-top: 15px;
				}
				.address {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					padding-top: 15px;
				}
				.timer {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					padding-top: 15px;
				}
				.course {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					padding-top: 15px;
				}
			}
		}
		.skills_body {
			background: white;
			width: 819px;
			.courseTitle {
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				padding-bottom: 10px;
				padding-left: 29px;
				padding-top: 25px;
				color: #333333;
				span {
					padding-bottom: 8px;
					border-bottom: 2px solid #333333;
				}
			}
			::v-deep.courseContent {
				padding: 34px 41px 62px 41px;
				font-size: 14px;
				img {
					width: auto !important;
				}
				p {
					font-size: 14px;
					img {
						width: auto !important;
					}
				}
			}
		}
		.ideaCourse {
			width: 360px;
			background: white;
			float: right;
			// position: absolute;
			// top: 77px;
			// right: 0;
			.ideaCourseTitle {
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				padding: 21px 0px 0px 25px;
			}
			.ideaImg {
				padding: 0px 23px 40px 27px;
				.ideaImg_box {
					margin-top: 21px;
					position: relative;
					.idea_img {
						width: 310px;
						height: 174px;
					}
					p {
						position: absolute;
						bottom: 0;
						width: 310px;
						height: 33px;
						line-height: 33px;
						background: rgba($color: #000000, $alpha: 0.5);
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						text-align: center;
						color: #ffffff;
					}
				}
			}
		}
		.breadcrumb {
			padding-top: 34px;
			padding-bottom: 29px;
			::v-deep.el-breadcrumb {
				display: flex;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				.el-breadcrumb__item {
					.el-breadcrumb__inner {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}
				}
			}
			.el-breadcrumb::before {
				content: '您当前的位置：';
			}
		}
	}
}
</style>